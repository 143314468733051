import React from 'react';
import styled from 'styled-components';
// import AdButler from '../../components/ThankyouPages/AdButler';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import storage from '../../utils/storage';

const IntroBlock = styled.div`
  box-sizing: border-box;
  background: #349bd5;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  max-width: 980px;
  h1 {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    @media (max-width: 801px) {
      font-size: 15px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
  .ybm {
    font-size: 44px;
    font-weight: 500;
    @media (max-width: 801px) {
      font-size: 26px;
    }
  }
`;
const EcmsContainer = styled.div`
  font-family: 'Roboto', Arial, san-serif;
  width: calc(100% - 20px);
  max-width: 1170px;
  margin: auto;
  img {
    margin: 0;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
  }
  section {
    box-sizing: border-box;
    width: calc(100vw - 40px);
    max-width: 700px;
    padding: 10px 20px 10px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 3px 8px #555;
    margin-bottom: 10px;
    @media (max-width: 801px) {
      padding: 10px;
    }
  }
  .contactInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 980px;
    margin: auto;
    .phoneNumber {
      background: #07698e;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      a {
        color: #fff;
      }
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    .clickApply {
      height: 49px;
      background: #07698e;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    @media (max-width: 801px) {
      flex-wrap: wrap;
    }
  }
  .logoContainer {
    width: calc(100% - 20px);
    max-width: 500px;
    margin: 20px auto;
  }

  .questions {
    box-sizing: border-box;
    background: #dfe7ee;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    max-width: 980px;
    color: #333;
    padding-bottom: 20px;
    .questionsHeadline {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      font-style: italic;
    }
    .threeItemBlock {
      margin-top: 20px;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      .contentBlock {
        width: 31%;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        p {
          font-size: 14px;
          font-weight: 600;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 14px;
        }
        .iconContainer {
          width: 65px;
        }
        @media (max-width: 801px) {
          width: 100%;
        }
      }
      @media (max-width: 801px) {
        flex-wrap: wrap;
      }
    }
    .dividerContainer {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(to bottom, #869db2 0%, #93c7e3 100%);
      color: #fff;
      padding: 8px;
      border-radius: 10px;
      margin-bottom: 10px;
      h2 {
        font-family: 'Roboto', Arial, san-serif;
        font-weight: 400;
        text-shadow: 0 3px 6px #aaa;
        margin: 0;
        font-size: 30px;
        @media (max-width: 801px) {
          font-size: 15px;
        }
      }
    }
    .questionList {
      ul {
        display: block;
        column-count: 2;
        column-gap: 20px;
        font-size: 14px;
        font-weight: 600;
        padding-left: 5px;
        @media (max-width: 801px) {
          column-count: 1;
        }
        li {
          list-style-type: none;
          margin-bottom: 10px;
          background: url('https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooDisc.png')
            0 2px/12px no-repeat;
          padding-left: 20px;
          // font-style: italic;
        }
      }
    }
    .disclaimer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 10px;
        margin-left: 20px;
      }
      a {
        color: #2863a2;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 801px) {
    width: 100%;
    .ameriSave .no-bs .container .contactInfo {
      flex-wrap: wrap;
    }
  }
`;

const EcmsThankyou = () => (
  <>
    <EcmsContainer>
      <IntroBlock>
        <h1>
          <span className="ybm">You’ve been matched</span>
          <br />
          with our featured provider, CARRINGTON MORTGAGE SERVICES
        </h1>
      </IntroBlock>
      <div className="logoContainer">
        <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/cmslogo.png"
          alt="AmeriSave"
        />
      </div>
      <section className="questions">
        <div>
          <p className="questionsHeadline">
            We make the dream of homeownership a reality by providing
            responsible home financing solutions. Carrington offers a variety of
            home buying and refinance loan programs that fit your needs.
          </p>
        </div>
        <div className="threeItemBlock">
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooHomeIcon.png"
                alt="Home Icon"
              />
            </div>
            <p>
              Trusted home financing experts. A team of industry pros to guide
              you each step of the way. Home loans aren't simple but we've got
              your back to ensure it's as seamless as possible.
            </p>
          </div>
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooInstitutionIcon.png"
                alt="Institution Icon"
              />
            </div>
            <p>
              Delivering a great customer experience. Reliability you can count
              on. A closing date you can depend on. Real-time updates to keep
              you informed along the way.
            </p>
          </div>
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooMoneyIcon.png"
                alt="Money Icon"
              />
            </div>
            <p>
              Loan solutions as unique as you are. Whether you're refinancing or
              purchasing a home, we're here to help. We unlock the next solution
              for your situation, including first-time homebuyers, those with
              less-than-perfect credit, or veterans.
            </p>
          </div>
        </div>
        <div className="dividerContainer">
          <h2>Have questions? Carrington Mortgage Services can help!</h2>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooQuestionIcon.png"
            alt="Question Icon"
          />
        </div>
        <div className="questionList">
          <ul>
            <li>Can I lower my monthly mortgage payment?</li>
            <li>Will I close within 30 days?</li>
            <li>
              Will I receive important updates and communications from my
              lender?
            </li>
            <li>Can I complete the application process online?</li>
            <li>Is now a good time for me to switch to a 15-year fixed?</li>
          </ul>
        </div>
      </section>
    </EcmsContainer>
  </>
);

export default withThankyouWrapper(EcmsThankyou);
